import { FC } from "react";
import { Link } from "react-location";
import { gql, useQuery } from "@apollo/client";
import _ from "lodash";
import { classNames } from "utils";
import { PlusSmallIcon } from "@heroicons/react/20/solid";
import numeral from "numeral";

const stats = [
  {
    name: "Revenue",
    accessor: "summary.totalRevenue",
    prescript: "GHS",
    format: "0,0.00",
  },
  {
    name: "Captured",
    accessor: "summary.totalCustomers",
    postscript: "Customers",
    format: "0,0",
  },
];
const statuses: any = {
  Paid: "text-green-700 bg-green-50 ring-green-600/20",
  Withdraw: "text-gray-600 bg-gray-50 ring-gray-500/10",
  Overdue: "text-red-700 bg-red-50 ring-red-600/10",
};

const GET_REGULARIZATIONS = gql`
  query GetRegularizations {
    summary: getRegularizationUserSummary {
      totalRevenue
      totalCustomers
    }
    regularizations: getRegularizations(sort: "-createdAt") {
      _id
      code
      accountCode
      servicePointCode
      customerData {
        customerType

        organization {
          name
          taxIdentificationNumber
          organizationRegistrationNumber
          organizationRegistrationDate
          organizationRegistrationDocumentUrl
          certificateOfIncorporationDocumentUrl
        }
        representative {
          title
          fullName
          nationality
          dateOfBirth
          gender
          phoneNumber
          emailAddress
          profileImageUrl
          hasGhanaCard
          ghanaCardNumber
          ghanaCardIssueDate
          ghanaCardExpiryDate
          ghanaCardFrontImageUrl
          ghanaCardBackImageUrl
          identityCardType
          identityCardNumber
          identityCardIssueDate
          identityCardExpiryDate
          identityCardFrontImageUrl
          identityCardBackImageUrl
        }
      }
      property {
        owner {
          fullName
          phoneNumber
        }
        ghanaPostAddress
        community
        streetName
        houseNumber
        structureNumber
        landmark
        premiseType {
          _id
          code
          name
        }
        premiseCategory {
          _id
          code
          name
        }
        activity {
          _id
          code
          name
        }
        subActivity {
          _id
          code
          name
        }
        geoLocation {
          type
          coordinates
        }
        sitePlanDocumentUrl
      }
      service {
        serviceType
        serviceClass
        meterPhase
        energyCertificateNumber
        energyCertificateDocumentUrl
      }
      billing {
        neighbourhoodClass
        numberOfRooms
        numberOfMonths
        hasMeter
        meterNumber
        meterImageUrl
      }
      reading {
        flatMonthlyKWh
        previousReadingDate
        previousReadingValue
        currentReadingDate
        currentReadingValue
      }
      bill {
        totalConsumption
        billPeriod
        billStartDate
        billEndDate
        consumptionPeriod
        previousReadingDate
        currentReadingDate
        previousReadingValue
        currentReadingValue
        totalBillAmount
      }
      createdAt
      updatedAt
    }
  }
`;

const DashboardPage: FC = () => {
  const { data } = useQuery(GET_REGULARIZATIONS, {
    pollInterval: 1000,
  });

  return (
    <div className='bg-gray-100 h-full overflow-y-auto flex flex-col'>
      <div className='relative isolate  bg-gray-100'>
        {/* Secondary navigation */}
        <header className='py-4'>
          <div className='mx-auto flex max-w-7xl flex-wrap items-center gap-6 px-4 sm:flex-nowrap sm:px-6 lg:px-8'>
            <Link
              to='/'
              className='text-base font-semibold leading-7 text-gray-900'
            >
              Dashboard
            </Link>
            <Link
              to='/new-regularization'
              className='ml-auto flex items-center gap-x-1 rounded-md bg-primary-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600'
            >
              <PlusSmallIcon className='-ml-1.5 h-5 w-5' aria-hidden='true' />
              New Capture
            </Link>
          </div>
        </header>

        <div className='border-b border-b-gray-900/10 lg:border-t lg:border-t-gray-900/5'>
          <dl className='mx-auto max-w-7xl flex lg:px-2 xl:px-0 overflow-y-auto no-scrollbar divide-x divide-gray-900/5'>
            {stats.map((stat, statIdx) => (
              <div
                key={stat.name}
                className={classNames(
                  statIdx % 2 === 1
                    ? "sm:border-l"
                    : statIdx === 2
                    ? "lg:border-l"
                    : "",
                  "flex items-baseline flex-wrap flex-shrink-0 justify-between gap-y-2 gap-x-4 border-t border-gray-900/5 px-4 py-10 sm:px-6 lg:border-t-0 xl:px-8 w-[80%] bg-white"
                )}
              >
                <dt className='text-sm font-medium leading-6 text-gray-500'>
                  {stat.name}
                </dt>
                <dd className='w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900'>
                  {stat.prescript ?? ""}{" "}
                  {numeral(_.get(data, stat.accessor, 0)).format(stat.format)}{" "}
                  {stat.postscript ?? ""}
                </dd>
              </div>
            ))}
          </dl>
        </div>

        <div
          className='absolute left-0 top-full -z-10 mt-96 origin-top-left translate-y-40 -rotate-90 transform-gpu opacity-20 blur-3xl sm:left-1/2 sm:-ml-96 sm:-mt-10 sm:translate-y-0 sm:rotate-0 sm:transform-gpu sm:opacity-50'
          aria-hidden='true'
        >
          <div
            className='aspect-[1154/678] w-[72.125rem] bg-gradient-to-br from-[#FF80B5] to-[#9089FC]'
            style={{
              clipPath:
                "polygon(100% 38.5%, 82.6% 100%, 60.2% 37.7%, 52.4% 32.1%, 47.5% 41.8%, 45.2% 65.6%, 27.5% 23.4%, 0.1% 35.3%, 17.9% 0%, 27.7% 23.4%, 76.2% 2.5%, 74.2% 56%, 100% 38.5%)",
            }}
          />
        </div>
      </div>

      <div className='flex-1 flex flex-col'>
        <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 py-6'>
          <h2 className='mx-auto max-w-2xl text-base font-semibold leading-6 text-gray-900 lg:mx-0 lg:max-w-none'>
            Recently Captured
          </h2>
        </div>
        <div className='overflow-y-auto overflow-hidden border-t border-gray-100 bg-white flex-1'>
          {data?.regularizations?.length ? (
            <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8'>
              <div className='mx-auto max-w-2xl lg:mx-0 lg:max-w-none'>
                <table className='w-full text-left'>
                  <thead className='sr-only'>
                    <tr>
                      <th>Amount</th>
                      <th className='hidden sm:table-cell'>Client</th>
                      <th>More details</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.regularizations?.map((item: any) => (
                      <tr key={item?._id}>
                        <td className='relative py-5 pr-6'>
                          <div className='flex gap-x-6'>
                            <div className='flex-auto'>
                              <div className='flex items-start gap-x-3'>
                                <div className='text-sm font-medium leading-6 text-gray-900'>
                                  {item?.customerData?.representative
                                    ?.fullName ??
                                    item?.customerData?.representative
                                      ?.fullName}
                                </div>
                              </div>
                              <div className='mt-1 text-xs leading-5 text-gray-500'>
                                Surcharge:{" "}
                                <span className='text-gray-900'>
                                  {" "}
                                  GHS{" "}
                                  {numeral(item?.bill?.totalBillAmount).format(
                                    "0,0.00"
                                  )}
                                </span>
                              </div>
                              <div className='mt-1 text-xs leading-5 text-gray-500'>
                                Consumption:{" "}
                                <span className='text-gray-900'>
                                  {" "}
                                  {item?.bill?.totalConsumption} KWh
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className='absolute bottom-0 right-full h-px w-screen bg-gray-100' />
                          <div className='absolute bottom-0 left-0 h-px w-screen bg-gray-100' />
                        </td>
                        <td className='py-5 text-right'>
                          <div className='flex justify-end'>
                            <a
                              href={item?.href}
                              className='text-sm font-medium leading-6 text-primary-600 hover:text-primary-500'
                            >
                              <div
                                className={classNames(
                                  statuses[item?.billing?.neighbourhoodClass],
                                  "rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset"
                                )}
                              >
                                {item?.billing?.neighbourhoodClass}
                              </div>
                            </a>
                          </div>
                          <div className='mt-1 text-xs leading-5 text-gray-500'>
                            Account #
                            <span className='text-gray-900'>
                              {" "}
                              {item?.accountCode || item?.code}
                            </span>
                          </div>
                          <div className='mt-1 text-xs leading-5 text-gray-500'>
                            SPN #
                            <span className='text-gray-900'>
                              {" "}
                              {item?.servicePointCode || item?.code}
                            </span>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <div className='h-full flex items-center justify-center'>
              <div className='text-center'>
                <svg
                  className='mx-auto h-12 w-12 text-gray-400'
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke='currentColor'
                  aria-hidden='true'
                >
                  <path
                    vectorEffect='non-scaling-stroke'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth={2}
                    d='M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z'
                  />
                </svg>
                <h3 className='mt-2 text-sm font-semibold text-gray-900'>
                  No Regularization
                </h3>
                <p className='mt-1 text-sm text-gray-500'>
                  Get started by creating a new regularization.
                </p>
                <div className='mt-6'>
                  <Link
                    to='/new-regularization'
                    className='inline-flex items-center rounded-md bg-primary-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600'
                  >
                    <PlusSmallIcon
                      className='-ml-0.5 mr-1.5 h-5 w-5'
                      aria-hidden='true'
                    />
                    New Capture
                  </Link>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DashboardPage;
